import React, { Component } from 'react';
import axios from 'axios';
import './Instagram.css';


class Instagram extends Component{
    state = {
       data: null
    }

    componentDidMount(){
        axios.get("https://api.instagram.com/v1/users/self/media/recent/?access_token=5763124233.f264a8c.ab944e3ea1e2409fbffb05fd7de49fde")
        .then(response => {
            this.setState((prevState, props)=>{
                return {
                    data: response.data.data,
                };
            })
        });

    }

    render(){ 
        let pictures = null;
        if(this.state.data){
            
            pictures = pictures = this.state.data.map((picture) => {
                return(
                    <div className="instagram-photo-wrapper" key={picture.id}>
                        <a href={picture.link} target="_blank" rel="noopener noreferrer"><img src={picture.images.thumbnail.url} className="instagram-photo" alt=""/></a>
                    </div>
                )
            });
            
        }

        return (
            <section className="instagram-section">
                <div className="instagram-title">let's be insta-friends!</div>
                <div className="instagram-username">@bhumiphotography</div>
                <div className="instagram-gallery">
                    {pictures}
                </div>
            </section>
        )
    }
}
export default Instagram;