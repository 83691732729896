import React, { Component } from 'react';
import classes from './KindWords.module.css';
import renderHTML from 'react-render-html';
import axios from 'axios';
import WallGallery from '../../Widgets/WallGallery/WallGallery'

class KindWords extends Component{
    state = {
        coments: null,
        photos: null
    }


    componentDidMount(){
        document.querySelector('.loader-container').style.display = 'flex';

        document.querySelector('main').style.opacity = '0';
        document.querySelector('footer').style.opacity = '0';

    
        axios.get("wp-json/wp/v2/kindwords?slug=kind-words")
        .then(response => {
            let acf = response.data[0].acf;

            this.setState({
                coments: acf.coments,
                photos: acf.gallery
            })

            document.querySelector('.loader-container').style.display = 'none';
            document.querySelector('main').style.opacity = '1';
            document.querySelector('footer').style.opacity = '1';

        });
    
    }

    render(){
        let coments = null;
        
        if(this.state.coments && this.state.photos){
            coments = this.state.coments.map( (coment, index) =>{
                return(
                    <div className={classes.CommentWrapper} key={index}>
                        <p>
                        {renderHTML(coment.coment)} <span className={classes.CommentBy}> {renderHTML(coment.coment_by)} </span>
                        </p> 
                    </div>
                )
            })

            return(
                <main className={classes.KindWordsContent}>
                    <div className={classes.ComentContainer} >
                        {coments}
                    </div>

                    <WallGallery photos={this.state.photos} />

                </main>
            )

        }

        return(
            <main className={classes.KindWordsContent}>
            </main>
        )
    }
}
export default KindWords;