import React, { Component } from 'react';
import './Blog.css';

class Blog extends Component {
    render() {
        return (
            <section className="blog-content">
                <h1>Coming Soon!</h1>
            </section>
        )
    }
}
export default Blog;