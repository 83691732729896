import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import axios from 'axios';
import './Home.css';

//import SlideShow from '../../Widgets/SlideShow/SlideShow';
import CircularCarousel from '../../Widgets/CircularCarousel/CircularCarousel';
import Instagram from '../../Widgets/Instagram/Instagram';

class Home extends Component{
    state = {
        slideshow: null,
        pageTitle: '',
        pageParagraph: ''
        
    }
    
    componentDidMount(){
        document.querySelector('.loader-container').style.display = 'flex';

        document.querySelector('main').style.opacity = '0';
        document.querySelector('footer').style.opacity = '0';

        axios.get("/wp-json/wp/v2/home")
        .then(response => {
            let pageTitle = response.data[0].title.rendered;
            let acf = response.data[0].acf;

            this.setState((prevState, props)=>{
                return {
                    pageTitle: pageTitle,
                    slideshow: acf.slideShow,
                    pageParagraph: acf.pageParagraph
                };
            })

            document.querySelector('.loader-container').style.display = 'none';
            document.querySelector('main').style.opacity = '1';
            document.querySelector('footer').style.opacity = '1';

        });
    }

    render(){ 
        if(this.state.slideshow){
            return (
                <main>
                    <h1>{this.state.pageTitle}</h1>

                    <CircularCarousel images={this.state.slideshow}/>
                    
                    <section className="home-content">
                        {renderHTML(this.state.pageParagraph)}
                    </section>

                    <Instagram />
                    
                </main>
            )
        }else{
            return (
                <main>Loading.....</main>
            )
        }

        
    }
}
export default Home;