import React, { Component } from 'react';
import './PhotoGallery.css';

import LightBox from '../../Widgets/LightBox/LightBox';

class PhotoGallery extends Component {
    state = {
        photos: this.props.photos,
    };

    componentWillReceiveProps(nextProps) {
        this.setState((prevState, props)=>{
            return {
                photos: nextProps.photos
            };
        })
    }

    showLightBoxImage = (photo, index) => {
        this.setState((prevState, props)=>{
            return {
                singlePhoto: photo,
                currentIndex: index,
                showLightBox: true
            };
        })
    }
    
    closeLightBoxImage = () => {
        this.setState((prevState, props)=>{
            return {
                showLightBox: false
            };
        })
    }
    
    nextLightBoxImage = () => {
        let index = this.state.currentIndex;
        if ( index < this.state.photos.length - 1) {
            index++;
            this.switchLightBoxImages(index);
        }
    }
    
    prevLightBoxImage = () => {
        let index = this.state.currentIndex;
        if (index > 0 ) {
            index--;
            this.switchLightBoxImages(index);
        }
    }
    
    switchLightBoxImages = (index) =>{
        document.querySelector('.site-light-box-image').style.opacity = 0;
        setTimeout(() => {
            this.setState((prevState, props)=>{
                return {
                    currentIndex: index,
                    singlePhoto: this.state.photos[index], 
                };
            })
            document.querySelector('.site-light-box-image').style.opacity = 1;
        }, 400);
    }

    render(){

        let photos = this.state.photos.map((photo, index) => {
        
            return (
                <div className="photo-gallery" key={index} onClick={() => { this.showLightBoxImage(photo, index) }}>
                    <img src={photo.sizes.medium} big-src={photo.url} index={index} alt={photo.alt} />
                    <p className="caption">{photo.caption}</p>
                </div>
            )
        });

        return(
            <div>
                <LightBox
                    photo={this.state.singlePhoto}
                    showLightBox={this.state.showLightBox}
                    close={this.closeLightBoxImage}
                    next={this.nextLightBoxImage} 
                    prev={this.prevLightBoxImage}
                    currentIndex={this.state.currentIndex}
                    photosLength={this.state.photos.length}/>

                <section className="portfolio-gallery">
                    {photos}
                </section>
            </div>
        )
    }
}
export default PhotoGallery;