import React, { Component, Fragment } from 'react';
import renderHTML from 'react-render-html';
import axios from 'axios';
import './Gallery.css';

import PhotoGallery from '../../Widgets/PhotoGallery/PhotoGallery';

//import SlideShow from '../../Widgets/SlideShow/SlideShow';
import CircularCarousel from '../../Widgets/CircularCarousel/CircularCarousel';

class Gallery extends Component{
    state = {
        slug: this.props.match.params.slugid,
        pageTitle: null,
        pageParagraph: null,
        imageParagraph: null,
        slideshow: null,
        photos:null
    }

    componentDidMount = () => {
        document.querySelector('.loader-container').style.display = 'flex';
        document.querySelector('main').style.opacity = '0';
        document.querySelector('footer').style.opacity = '0';

        axios.get("/wp-json/wp/v2/gallery?slug="+this.props.match.params.slugid)
        .then(response => {
            let pageTitle = response.data[0].title.rendered;
            let acf = response.data[0].acf;

            this.setState((prevState, props)=>{
                return {
                    pageTitle: pageTitle,
                    pageParagraph: acf.pageparagraph,
                    imageParagraph: acf.paragraph_image,
                    slideshow: acf.slide_show,
                    photos:acf.gallery
                };
            })
            
            document.querySelector('.loader-container').style.display = 'none';
            document.querySelector('main').style.opacity = '1';
            document.querySelector('footer').style.opacity = '1';

        });
    }

    //componentDidUpdate = (prevProps, prevState) => {
    componentDidUpdate = (prevProps) => {
        
        if(prevProps.match.params.slugid !== this.props.match.params.slugid){

            document.querySelector('.loader-container').style.display = 'flex';
            document.querySelector('main').style.opacity = '0';

            axios.get("/wp-json/wp/v2/gallery?slug="+this.props.match.params.slugid)
            .then(response => {
                let pageTitle = response.data[0].title.rendered;
                let acf = response.data[0].acf;

                this.setState((prevState, props)=>{
                    return {
                        pageTitle: pageTitle,
                        pageParagraph: acf.pageparagraph,
                        imageParagraph: acf.paragraph_image,
                        slideshow: acf.slide_show,
                        photos:acf.gallery
                    };
                })
                
                document.querySelector('.loader-container').style.display = 'none';
                document.querySelector('main').style.opacity = '1';
                
            });
        }
    }

    render(){ 

        if(this.state.pageTitle){

            let photoGallery = null;
            let twoColumns = null; 

            if(this.state.imageParagraph){
                twoColumns = (
                    <section className="portfolio-content content">
                        <div className="gallery-content-row">
                            <div className="gallery-colum-2">
                                <figure>
                                    <img className="gallery-photo" src={this.state.imageParagraph.url} alt="" />
                                </figure>
                            </div>
                            <div className="gallery-colum-2 gallery-flex-section" >
                                {renderHTML(this.state.pageParagraph)}
                            </div>
                        </div>
                    </section>
                )
            }else{
                photoGallery = (
                    <Fragment>
                        <section className="portfolio-content content" style={{ width: '95%' }}>
                            {renderHTML(this.state.pageParagraph)}
                        </section>
                        <PhotoGallery photos={this.state.photos} />
                    </Fragment>
                )
            }

            return(
                <main>
                    <h1>{renderHTML(this.state.pageTitle)}</h1>

                    <CircularCarousel images={this.state.slideshow}/>

                    {twoColumns}

                    {photoGallery}
                    
                    
                </main>
            )
        }else{
            return (
                <main>Loading...</main>
            )
        }

        
    }
}
export default Gallery;