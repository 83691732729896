import React from 'react';
import './Loading.css';
import ajaxLoader from '../../assets/images/ajax-loader.gif'

const loading = (props) => {

    let customClasses = ['bars-wrapper'];
    if(props.isMenuShown){
        customClasses.push("change");
    }
    
    return (
        <div className="loader-container">
            <div className="loader-wrapper">
                <div className="loader-image">
                    <img src={ajaxLoader} alt="ajax-loader" className="ajax-loader"/>
                </div>
                <div className="loader-caption">
                    Loading...
                </div>
            </div>
        </div>
    );
}
export default loading;