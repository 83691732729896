import React, { Component } from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom';


import MobileNavBar from './MobileNavBar/MobileNavBar';
import Navigation from './Navigation/Navigation';

import './Header.css';
import logoImage from '../assets/images/logos/logo.png';


class Header extends Component{
    
    state = {
        isMobile: false,
        isMenuShown: false,
        links: null
    }

    componentDidMount(){
        axios.get("/wp-json/wp-api-menus/v2/menus/2")
        .then(response => {
            this.setState((prevState, props)=>{
                return {
                    links: response.data.items
                };
            })

            document.querySelector('.loader-container').style.display = 'none';
            document.querySelector('.site-component-wrapper').style.opacity = '1';
        });
    }
    

    addWindowsListeners = () => {
        window.addEventListener("resize", this.setIsMobile);
        window.addEventListener("load", this.setIsMobile);
    }

    setIsMobile = () => {
        let width = document.querySelector('body').clientWidth;
        if (width <= 888) {
            this.setState((prevState, props)=>{
                return {
                    isMobile: true
                };
            })
        } else {
            this.setState((prevState, props)=>{
                return {
                    isMobile: false,
                    isMenuShown: false, 
                };
            })
        }
    }

    toggleMenu = (event) => {
        //let el = event.target;
        //let el = event.currentTarget;
        if(this.state.isMenuShown){
            this.setState((prevState, props)=>{
                return {
                    isMenuShown: false
                };
            })
            //el.classList.remove("change");
        }else{
            this.setState((prevState, props)=>{
                return {
                    isMenuShown: true
                };
            })
            //el.classList.add("change");
        }
    }

    render(){

        this.addWindowsListeners();

        let navbar = null;
        let logo = null;

        if(this.state.isMobile){
            navbar = <MobileNavBar click={this.toggleMenu} isMenuShown={this.state.isMenuShown}/>
        }else{
            logo = (
                <Link to="/" >
                <div className="logo-wrapper">
                    <img className="logo-image" src={logoImage} alt="Desktop Logo"/>
                </div>
                </Link>
            );
        }  
        
        return (
            <header className="site-header">
                
                {logo}
                {navbar}
                <Navigation 
                    links={this.state.links} 
                    isMobile={this.state.isMobile} 
                    isMenuShown={this.state.isMenuShown} 
                    toggleMenu={this.toggleMenu}/>
            </header>
        )
    }
}
export default Header;