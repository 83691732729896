import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';

import Loader from './Widgets/Loading/Loading'
import Header from './Header/Header';
import Home from './Pages/Home/Home';
import Gallery from './Pages/Gallery/Gallery';
import Prices from './Pages/Prices/Prices';
import Contact from './Pages/Contact/Contact';
import Blog from './Pages/Blog/Blog'
import KindWords from './Pages/KindWords/KindWords';
import Footer from './Footer/Footer';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <Loader />
          <div className="site-component-wrapper"> 
            <Header />
              <Route exact path="/" component={Home} />
              <Route path="/gallery/:slugid" component={Gallery} />
              <Route path="/contact" component={Contact} />
              <Route path="/pricing" component={Prices} />
              <Route path="/Blog" component={Blog} />
              <Route path="/KindWords" component={KindWords} />
            <Footer />
          </div>
      </BrowserRouter>
    );
  }
}

export default App;
