import React from 'react';
import './Footer.css';
import facebookLogo from '../assets/images/social-media/facebook.png';
import instagramLogo from '../assets/images/social-media/instagram.png';
import mailLogo from '../assets/images/social-media/mail.png';
import footerLogo from '../assets/images/logos/coloriconlogo.png';

const footer = (props) => {
    return (
        <footer className="site-footer">
            <section className="footer-section-left">
                <figure>
                    <img className="footer-logo" src={footerLogo} alt="footer logo"/>
                </figure>
            </section>
            <section className="footer-section-middle">
                <p>
                    Professional Photographer located in Mooresville North Carolina,
                    serving Charlotte and surrounding areas.
                    Available for Travel Worldwide!
		        </p>
                <p>
                    &copy; {new Date().getFullYear()} BHUMI PHOTOGRAPHY
		        </p>
            </section>
            <section className="footer-section-right">
                <h2>Connect</h2>
                <div className="social-media-wrapper">
                    <a href="https://www.facebook.com/bhumiphotographystudio" target="_blank" rel="noopener noreferrer"><img className="social-icon" src={facebookLogo} alt="facebook" /></a>
                    <a href="https://www.instagram.com/bhumiphotography/" target="_blank" rel="noopener noreferrer"><img className="social-icon" src={instagramLogo} alt="instagram" /></a>
                    <a href="mailto:diana@bhumiphotography.com"><img className="social-icon" src={mailLogo} alt="mail"  /></a>
                </div>
            </section>
        </footer>
    );
}
export default footer;