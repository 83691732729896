import React from 'react';
import classes from './WallGallery.module.css';

const wallGallery = (props) =>{
    let photos = () => {
        return  props.photos.map( (photo, index) => {
            return (
                <img src={photo.url} key={index} alt={ ("photo-" + index) }/>
            )
        } )
    } 

    return(
        <section className={classes.Photos}>
            {photos()}
        </section>  
    )

}
export default wallGallery;