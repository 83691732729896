import React, { Component } from 'react';
import './Prices.css';

import axios from 'axios';
import renderHTML from 'react-render-html';



class Prices extends Component{
    state = {
        pageParagraph: null,
        sections: null
    }


    componentDidMount = () => {
        document.querySelector('.loader-container').style.display = 'flex';
        document.querySelector('main').style.opacity = '0';
        document.querySelector('footer').style.opacity = '0';

        axios.get("wp-json/wp/v2/prices")
        .then(response => {
            let acf = response.data[0].acf;
            this.setState((prevState, props)=>{
                return {
                    pageParagraph: acf.pageParagraph,
                    sections: acf.sections
                };
            })
            
            document.querySelector('.loader-container').style.display = 'none';
            document.querySelector('main').style.opacity = '1';
            document.querySelector('footer').style.opacity = '1';

        });
    }

    render(){
        
        let pageContent = null;
        if(this.state.sections){
            pageContent = this.state.sections.map( (section, index) => {
                
                var items = section.inner_fields.map( (item, index2) => {
                    let captionStyle = {
                        fontFamily: "ClickerScript-Regular",
                        color: "#cc9933"
                    }

                    let figure = null;
                    if(item.image){
                        figure = (
                            <figure>
                                <img className="price-photo" src={item.image.sizes.medium} alt={item.image.caption} />
                            </figure>
                        )
                    }
                    
                    let caption = null
                    if(item.caption){
                        caption = (
                            <div className="price-caption" >
                                <p style={captionStyle}>{item.caption}</p>
                            </div>
                        )
                    }

                    return (
                        <div className="site-colum-2" key={index2}>
                            {figure}
                            {caption}
                        </div>
                    )
                })
                
                return(
                    <section className="price-content content" style={{borderBottom: "20px solid #6e9e96"}} key={index}>
                        <div className="price-content-row">
                            {items}
                        </div>
                    </section>
                )
            })
        }

        let pageParagraph = null;
        if(this.state.pageParagraph){
            pageParagraph = (
                <section className="price-page-paragrpah"> 
                    {renderHTML(this.state.pageParagraph)}
                </section>
            )
        }

        

        return (
            <main>
                {pageContent}  
                {pageParagraph}
            </main>
        );
    }
    
}
export default Prices;



