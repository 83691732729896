import React from 'react';
import './ContactForm.css';

const contactForm = (props) => {

    return (
        <section className="contact-form-section">
            <form>
                <div className="contact-content-row">
                    <div className="site-colum-2">
                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="first name"
                            required pattern="[A-Za-z\s]{1,32}"/>
                    </div>
                    <div className="site-colum-2">
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="last name"
                            required pattern="[A-Za-z\s]{1,32}"/>
                    </div>
                </div>

                <div className="contact-content-row">
                    <div className="site-colum-2">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="email address"
                            required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"/>
                    </div>
                    <div className="site-colum-2">
                        <input
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="phone number"
                            required
                            pattern="[0-9]{10}"/>
                    </div>
                </div>

                <div className="contact-content-row">
                    <div className="site-colum-1">
                        <input type="text" name="sessionType" id="sessionType" placeholder="Session Type" required pattern="[a-zA-Z0-9\s]+" />
                    </div>
                </div>

                <div className="contact-content-row">
                    <div className="site-colum-1">
                        <textarea name="message" id="message"  placeholder="message" required></textarea>
                    </div>
                </div>

                <div className="contact-form-submit-wraper">
                    <button type="submit" className="contact-form-submit-button">Submit</button>
                </div>

            </form>
        </section>
    );
}
export default contactForm;