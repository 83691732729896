import React from 'react';
import './LightBox.css';

const lightBox = (props) => {

    const customClasses =['site-light-box'];
    if(props.showLightBox){
        customClasses.push("show-lightbox");
    }

    let prevButton;
    if(props.currentIndex > 0){
        prevButton = (<span className="site-light-box-prev" onClick={props.prev}>&#10094;</span>)
    }

    let nextButton;
    if(props.currentIndex < props.photosLength -1){
        nextButton = (<span className="site-light-box-next" onClick={props.next}>&#10095;</span>)
    }
    
    let imageContainer = null;
    if(props.photo){
        imageContainer = (
            <figure>
                <img className="site-light-box-image" src={props.photo.url} alt={props.photo.alt}/>
            </figure>
        )
    }
    
    return (
        
        <div className={customClasses.join(" ")} >
            <div className="site-light-box-container">
                <div className="site-light-box-wrapper">
                    {imageContainer}
                </div>
                <span className="site-light-box-close" onClick={props.close}>&times;</span>
                {prevButton}
                {nextButton}
            </div>
        </div>
    );
}
export default lightBox;
