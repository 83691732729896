import React, { Component } from 'react';
import './Contact.css';

import ContactForm from './ContactForm/ContactForm'

class Contact extends Component {
    render() {
        return (
            <main>
                <section className="contact-content content">
                    <div className="contact-content-row">
                        <div className="site-colum-2">
                            <h1 className="rotate-20">Get in Touch!</h1>
                        </div>
                        <div className="site-colum-2">
                            <figure>
                                <img className="contact-photo" src="images/photos/contact-photo-1.png" alt=""/>
                            </figure>
                        </div>
                    </div>
                    <div className="contact-content-row">
                        <div className="site-colum-2">
                            <figure>
                                <img className="contact-photo" src="images/photos/contact-photo-2.png" alt=""/>
                            </figure>
                        </div>
                        <div className="site-colum-2">
                            <p>
                                Thank you for your interest in Bhumi Photography. Whether you are looking for all posed pictures or a mix between posed and candid I
                                can help you create memories that will last a lifetime. I would love to answer any questions you may have. Please fill out the contact
                            form, or email me directly at <a href="mailto:diana@bhumiphotography.com?Subject=Contact%20Us" target="_top">diana@bhumiphotography.com</a>
                                and I’ll get back to you as soon as I can! I look forward to chatting with you!
                        </p>
                        </div>
                    </div>
                </section>

                <ContactForm />

            </main>
        )
    }
}
export default Contact;