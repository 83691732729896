import React from 'react';
import { Link} from 'react-router-dom';
import mobileLogo from '../../assets/images/logos/coloriconlogo.png'

const mobileNavBar = (props) => {

    let customClasses = ['bars-wrapper'];
    if(props.isMenuShown){
        customClasses.push("change");
    }
    
    return (
        <section className="mobile-nav-bar">
            <figure className="mobile-logo-container">
                <Link to="/" ><img className="mobile-logo" src={mobileLogo} alt="Mobile Logo"/></Link>
            </figure>
            <div onClick={props.click}  className={customClasses.join(" ")}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        </section>
    );
}
export default mobileNavBar;