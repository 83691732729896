import React from 'react';
//import { Link, NavLink } from 'react-router-dom';
import { Link} from 'react-router-dom';
import renderHTML from 'react-render-html';

const navigation = (props) => {

    const toggleAccordion = (event) => {
        event.preventDefault();
        let el = event.currentTarget;
        let panel = el.nextElementSibling;
        if(props.isMobile){
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        }
    }

    const renderMenu = (links) => {
        return links.map( (link, index) => {
            if(link.children){
                return (
                    <li key={index}>
                        <a href="#accordion" onClick={(event) => {toggleAccordion(event)} }>
                            {renderHTML(link.title)} <span className="arrow arrow-down"></span>
                        </a>
                        <ul className="menu-panel">
                            {renderMenu(link.children)}
                        </ul>
                    </li>
                )
            }else{
                if(link.object === "custom"){
                    return (
                        <li key={index}>
                            <a href={link.url} target="_bank" > {renderHTML(link.title)} </a>
                        </li>
                    )
                }else{
                    let toRoute = []; // template to use
                    if(link.type_label !== 'Home'){
                        toRoute.push(link.type_label.replace(" ",""));
                    }

                    if(link.parent !== 0 ){
                        toRoute.push(link.object_slug) // the page to load
                    }
                        

                    let cleanRoute = '/';

                    cleanRoute+= toRoute.join("/");

                    return (
                        <li key={index}>
                            <Link to={cleanRoute} onClick={props.toggleMenu} >{renderHTML(link.title)} </Link>
                        </li>
                    )
                }
            }
            
        })
    }

    let navigation = null;
    if(props.links){
        navigation = renderMenu(props.links)
    }

    let style = {};
    if(props.isMobile){
        if(props.isMenuShown){
            style = {left: '0'};
        }else{
            style = {left: '-100%'};
        }
    }
    
    return (
        <nav className="main-menu" style={style}>
            <ul>
                {navigation}
            </ul>
        </nav>
    );
}
export default navigation;